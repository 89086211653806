@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,900&display=swap");

// COLORS, MAKE SURE TO ADD INTO THEME COLORS DICT AS WELL. THESE COLORS SHOULD ALSO MATCH COLORS IN COLORS.TSX
$purple: #8a4d76;
$light-purple: #bc6ca1;
$dark-purple: #94547f;
$orange: #ffa500;
$turquoise: #afeeee;
$black: #363636;
$pink: #fa7c91;
$brown: #757763;
$gray: #d0d1cd;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$dark-blue: #282d31;
$blue: #135ea0;
$light-blue: hsl(208, 79%, 55%);
$lighter-blue: #8dc3f2;
$yellow: #e7c534;
$yellow-light: hsl(57, 84%, 65%);
$red: hsl(5, 70%, 64%);
$green: hsl(125, 49%, 67%);

$darken: rgba(0, 0, 0, 0.04);

$text-strong: #12263f;
$text: hsla(215, 10%, 54%, 1);
$text-light: hsla(216, 15%, 72%, 1);

$theme-colors: ();
$theme-colors: map-merge(
  (
    "purple": $purple,
    "light-purple": $light-purple,
    "dark-purple": $dark-purple,
    "orange": $orange,
    "turquoise": $turquoise,
    "black": $black,
    "pink": $pink,
    "brown": $brown,
    "gray": $gray,
    "beige-light": $beige-light,
    "beige-lighter": $beige-lighter,
    "blue": $blue,
    "yellow": $yellow,
    "red": $red,
    "green": $green,
    "light-blue": $light-blue,
    "lighter-blue": $lighter-blue,
    "dark-blue": $dark-blue,
    "yellow-light": $yellow-light,
  ),
  $theme-colors
);

// Update Bulma's global variables
$family-sans-serif: "Inter", sans-serif;
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $yellow;
$info: $light-blue;
$link: $info;
$block-spacing: 1rem;
$shadow: 0 0.25em 0.5em -0.125em rgba(50, 50, 50, 0.075),
  0 0px 2px 1px rgba(50, 50, 50, 0.015);
$shadow-inner: 0 0.125em 0.25em -0.0875em rgba(50, 50, 50, 0.075);

$box-shadow: $shadow;
/* $box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03); */
$card-shadow: $shadow;

$card-header-shadow: $shadow-inner;

/* $widescreen-enabled: false;
$fullhd-enabled: false; */

$section-padding: 1rem 1.5rem;

//$body-size: 12px;

// Update some of Bulma's component variables
//$body-background-color: #eef0f6;
//$body-background-color: hsl(0, 0%, 95%);
$body-background-color: #f9fbfd;
$input-border-color: transparent;
$input-shadow: none;
$border: hsl(214, 10%, 94%);

// Menu

$menu-item-radius: 0;
$menu-item-active-background-color: transparent;
$menu-list-link-padding: 1rem;
$menu-item-active-color: $text-strong;

// Tabs

$tabs-link-active-border-bottom-color: $primary;
$tabs-link-active-color: $text-strong;

// Tooltip

$tooltip-background-color: black;
$tooltip-background-opacity: 1;
$tooltip-padding: 0.25rem 1rem;

// Box
$radius: 0.5rem;
$box-radius: $radius;
